<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>巡查管理</el-breadcrumb-item>
        <el-breadcrumb-item>双随机巡查列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt0">
      <div class="pt20"  style="text-align:left;">
        <el-form :inline="true" :model="page" class="demo-form-inline">
          <el-form-item>
            <el-select
              v-model="page.patrol_state"
              clearable
              placeholder="巡查状态"
              @change="onSubmit(page.patrol_state)"
            >
              <el-option key="" label="请选择巡查状态" value=""></el-option>
              <el-option key="1" label="未巡查" value="1"></el-option>
              <el-option key="2" label="已巡查" value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-date-picker
              type="date"
              clearable
              placeholder="巡查日期"
              v-model="page.patrol_time"
              :picker-options="pickerOptionsEnd"
              style="width: 100%"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="change(6, page.patrol_time)"
            ></el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" size="medium"
              >查询</el-button
            >
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="medium" @click="PatroleAdd"
              >双随机工单派发</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <!-- <el-row class="box search mt20">
       <el-form :inline="true" :model="page" class="demo-form-inline">
       <el-col :span="4" class="mr10">
         <el-form-item size="medium">
          <el-select v-model="page.patrol_state" clearable placeholder="巡查状态" @change="onSubmit(page.patrol_state)"> 
            <el-option key="" label="请选择巡查状态" value=""></el-option>
            <el-option key="1" label="未巡查" value="1"></el-option>
            <el-option key="2" label="已巡查" value="2"></el-option>
          </el-select>
        </el-form-item>
        </el-col>
        <el-col :span="4" class="mr10">
          <el-form-item size="medium">
             <el-date-picker type="date" clearable placeholder="巡查日期" v-model="page.patrol_time"  :picker-options="pickerOptionsEnd" style="width: 100%;" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="change(6,page.patrol_time)"></el-date-picker>
        </el-form-item>
        </el-col>
         <el-col :span="7" class="tl mr10">
           <el-form-item  size="medium">
          <el-button  type="primary"  @click="onSubmit" class="general_bgc general_border cfff">查询</el-button>
          <el-button type="primary" size="medium" @click="PatroleAdd">双随机工单派发</el-button>
        </el-form-item>
        </el-col>
      </el-form>
    </el-row> -->
      <div class="box list">
        <el-table border :data="tableData" style="width: 100%">
          <el-table-column
            type="index"
            align="center"
            width="60"
            label="序号"
          ></el-table-column>
          <el-table-column
            prop="work_order"
            min-width="120"
            :show-overflow-tooltip="true"
            label="工单号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="user_name_add"
            min-width="120"
            :show-overflow-tooltip="true"
            label="派发人"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="patrol_user_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="抽查人"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="company_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="抽查对象"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="add_time"
            min-width="120"
            :show-overflow-tooltip="true"
            label="派发时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="patrol_time"
            min-width="120"
            :show-overflow-tooltip="true"
            label="巡查时间"
            align="center"
          ></el-table-column>
          <el-table-column
            min-width="120"
            :show-overflow-tooltip="true"
            label="巡查结果"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.patrol_result == '不合格'" class="red"
                >不合格</span
              >
              <span v-else-if="scope.row.patrol_result == '合格'">合格</span>
              <span v-else>---</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80" fixed="right" align="center">
            <template slot-scope="scope">
              <el-button
                type="text"
                class="general_color"
                size="medium"
                @click="godetail(scope.row.patrol_id)"
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { session } from "../../../util/util";
import { mapState } from "vuex";
export default {
  data() {
    return {
      page: {
        pager_offset: "0",
        pager_openset: "10",
        patrol_time: "",
        patrol_state: "",
        patrol_type: "2",
      },
      timer: "",
      loading: false,
      options4: "",
      list_btn3: [
        { id: "1", title: "合格" },
        { id: "2", title: "不合格" },
      ],
      currentPage: 1,
      total: 0,
      tableData: [],
      pickerOptionsEnd: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  computed: {
    ...mapState({
      patrolPage1: (state) => state.patrol.patrolPage1,
    }),
  },
  // mounted() {

  //   },
  created() {
    this.page = Object.assign({}, this.patrolPage1);
    this.currentPage = this.page.pager_offset / 10 + 1;
    this.init(this.page);
  },
  methods: {
    init(params) {
      axios.get("/pc/patrol/list", params).then((v) => {
        this.tableData = v.data.patrol_list;
        this.total = v.data.pager_count;
      });
    },
    // 搜索
    onSubmit() {
      this.currentPage = 1;
      this.page.pager_offset = "0";
      this.$store.dispatch("patrol/setNewPage3", this.page);
      this.init(this.page);
    },
    // 分页
    currentChange(page) {
      this.currentPage = page;
      this.page.pager_offset = String((page - 1) * 10);
      this.$store.dispatch("patrol/setNewPage3", this.page);
      this.init(this.page);
    },
    PatroleAdd() {
      session.set("params", { id: 2, id1: "", id2: "", name: "" });
      this.$router.push({
        name: "PatrolAdd",
        query: {
          type: 1,
        },
      });
    },
    godetail(index) {
      session.set("params", { id: index, id1: "", id2: "", name: "" });
      this.$router.push({
        name: "WorkOrderDetail",
      });
    },
    //导出
    DownExcel() {
      this.loading = true;
      this.timer = setInterval(() => {
        this.check();
      }, 1000);
      this.currentPage = 1;
      this.page.pager_offset = "0";
      this.page.pager_openset = String(this.total);
      if (this.page.search_time_end == null) {
        this.page.search_time_end = "";
      }
      if (this.page.search_time_bigen == null) {
        this.page.search_time_bigen = "";
      }
      this.page.down_name = "随机抽查列表信息";
      axios
        .fileget("/api/report/export_patrol_double", this.page)
        .then((v) => {});
    },
    check() {
      axios
        .get("/api/report/check", { down_name: "export_patrol_double" })
        .then((v) => {
          if (v == true) {
            clearInterval(this.timer);
            this.loading = false;
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}
</style>
